import * as React from "react"

import Layout from "../components/Layout"
import HeadMeta from "../components/HeadMeta"
import PageHeader from "../components/PageHeader"
import Breadcrumb from "../components/Breadcrumb"
import SearchBlock from "../components/SearchBlock"

type Props = {
  search?: {
    search?: string;
    lang?: string;
  };
};

const SearchPage: React.FunctionComponent<Props> = ({ location }) => {
  const params = new URLSearchParams(location.search);
  const initialQuery = params.get('search') || "";
  const initialLang = params.get('lang') || "en";

  return (
    <Layout>
      <HeadMeta pageTitle="Search" />
      <PageHeader>Search</PageHeader>
      <Breadcrumb
        route={[
          { name: "Multilingual library signage", href: "/" },
          { name: "Search", href: "/search" },
        ]}
      />
      <SearchBlock initialQuery={initialQuery} initialLang={initialLang} />
    </Layout>
  )
};

export default SearchPage;

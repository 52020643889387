import React from "react"
import { Link } from "gatsby"

import Phrase from '../Phrase';
import SearchInput from "../SearchInput"
import SearchPager from '../SearchPager';

import { useLunr } from '../../lib/hooks';

import * as css from "./SearchBlock.module.scss"

type Props = {
  initialQuery?: string
  initialLang?: string
  limit: number
}

const SearchBlock = ({
  limit = 25,
  initialQuery = "",
  initialLang = "en",
}) => {
  const [lang, setLang] = React.useState(initialLang);
  const [query, setQuery] = React.useState(initialQuery);

  const [searched, setSearched] = React.useState(false);
  const [results, setResults] = React.useState([]);
  const [startAt, setStartAt] = React.useState(0);
  const [searchReady, runSearch] = useLunr(false, {
    waitTime: 500,
    waitCount: 40
  });
  const numResults = limit;

  const doSearch = async (currentQuery, currentLang, firstRun = false) => {
    if (!currentQuery?.length || !currentLang?.length) {
      setSearched(false);
      return;
    }
    setSearched(true);
    runSearch(currentQuery, currentLang)
      .then(res => setResults(res))
      .catch(e => console.error(e));
  }

  const firstItemIndex = startAt;
  const lastItemIndex = startAt + numResults;

  const pagedResults =
    results.length > 0 &&
    results.slice(firstItemIndex, lastItemIndex).map((r, index) => (
      <li key={index}>
        <Link to={`/${r.Slug}`}>
          {r.Type}
          {": "}
          {r.Type === "Translation" && (
            <Phrase text={`${r.LanguageName}: ${r.PhraseName} (${r.TranslatedPhrase})`} />
          )}
          {r.Type === "Phrase" && <Phrase text={r.PhraseName} />}
          {r.Type === "Language" && <Phrase text={r.LanguageName} />}
        </Link>
      </li>
    ))

  React.useEffect(() => {
    setQuery(initialQuery);
    setTimeout(() => {
      setLang(initialLang);
      setTimeout(() => {
        if (initialQuery?.length > 0 && initialLang?.length > 0) {
          doSearch(initialQuery, initialLang, true);
        }
      }, 500);
    }, 0);
  }, [initialQuery, initialLang])

  return (
    <div className={css.searchBlock}>
      <SearchInput
        initialQuery={initialQuery}
        initialLang={initialLang}
        onSubmit={(q, l) => {
          setQuery(q);
          setTimeout(() => {
            setLang(l);
            setTimeout(() => {
              doSearch(q, l);
            }, 500);
          }, 0);
        }}
      />
      <div className={css.searchResults}>
        {!searchReady && (
          <p>Preparing to search, please wait...</p>
        )}
        {searchReady && searched && (
          <>
            { results.length > 0 && (
              <>
                <ol>{pagedResults}</ol>
                <SearchPager
                  firstItemIndex={firstItemIndex}
                  lastItemIndex={lastItemIndex}
                  pageLength={numResults}
                  onSetStart={(newStart) => setStartAt(newStart)}
                  resultsCount={results.length}
                />
              </>
            )}
            {query.length > 0 && results.length === 0 && <p>No results found.</p>}
          </>
        )}
        {query.length === 0 && <p>Please select a language and enter a keyword above.</p>}
      </div>
      <p>
        <strong>Can't find your language here?</strong> It might not be
        supported by our search engine. Please{" "}
        <Link to="/language/">browse our full list of languages</Link> and see
        what phrases are available.
      </p>
    </div>
  )
}

export default SearchBlock
